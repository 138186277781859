import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginRequest } from 'src/app/models/authentication/login-request.interface';
import { ResponseHelper } from 'src/app/models/helpers/response-helper.interface';
import { environment } from 'src/environments/environment';
import { ConfiguracionReCaptcha } from 'src/app/models/settings/configuracionrecaptcha';
import { Store } from '@ngxs/store';
import { AuthStateModel } from 'src/app/models/states/auth.state.interface';
import { AuthState } from 'src/app/store/auth/states/auth.state';
import { UpdatePlantelAuthData } from 'src/app/store/auth/actions/auth.actions';
import { PlantelesService } from '../planteles/planteles.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private baseUrl = environment.apiUrl;
  private controller: string = "api/Account";
  public configuracion: ConfiguracionReCaptcha;
  constructor(private http:HttpClient,private store: Store,private plantelesService: PlantelesService) {}

  login(form:LoginRequest):Observable<ResponseHelper>{
    return this.http.post<ResponseHelper>(`${this.baseUrl}${this.controller}/login`,form);
  }

  forgotPass(data: FormData): Observable<ResponseHelper>{
    return this.http.post<ResponseHelper>(`${this.baseUrl}${this.controller}/forgotPassword`, data);
  }

  restorePass(data: FormData): Observable<ResponseHelper>{
    return this.http.post<ResponseHelper>(`${this.baseUrl}${this.controller}/restaurarContrasena`, data);
  }

  refresh(usuario: FormData): Observable<ResponseHelper>{
    return this.http.post<ResponseHelper>(`${this.baseUrl}${this.controller}/refresh`,usuario);
  }

  saveToken(token: string, role: string): void {
    localStorage.setItem('token', token);
    localStorage.setItem('role',role);
  }

  getToken(): string {
    return localStorage.getItem('token');
  }

  getUserRole(): string {
    return localStorage.getItem('role') || '';
  }

  getRecaptchaKeys(): Observable<ConfiguracionReCaptcha>{
    return this.http.get<ConfiguracionReCaptcha>(`${this.baseUrl}${this.controller}/recaptcha`);
  }

  loadRecaptchaKeys(): Promise<void> {
    // return this.getRecaptchaKeys().toPromise().then(config => {
    //   this.configuracion = config;
    // });

    return this.getRecaptchaKeys().toPromise()
    .then(config => {
      this.configuracion = config;
    })
    .catch(err => {
      this.configuracion = { llavePublica: '', llavePrivada:'', appUser: '' }; 
    });

  }

  saveIdPlantel(plantel: number | null)
  {
    if(plantel !== null){
      this.store.select(AuthState.authData).subscribe((authState: AuthStateModel) => {
        authState.idPlantel = plantel;
        this.store.dispatch(new UpdatePlantelAuthData(authState));
      });
      localStorage.setItem('idPlantel', plantel.toString());
    }else{
      this.store.select(AuthState.authData).subscribe((authState: AuthStateModel) => {
        authState.idPlantel = null;
        this.store.dispatch(new UpdatePlantelAuthData(authState));
      });
      this.plantelesService.setPlantelId(0);
      localStorage.removeItem('idPlantel');
    }
  }

  getPlantel(): string{
    return localStorage.getItem('idPlantel');
  }
}


import { Component } from '@angular/core';
import { ROUTES } from './side-nav-routes.config';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { SideNavInterface } from '../../interfaces/side-nav.type';

@Component({
    selector: 'app-sidenav',
    templateUrl: './side-nav.component.html'
})

export class SideNavComponent{

    public menuItems: any[]
    isFolded : boolean;
    isSideNavDark : boolean;
    isExpand : boolean;

    constructor( private themeService: ThemeConstantService, private authService: AuthenticationService) {}

    ngOnInit(): void {

        const userRole = this.authService.getUserRole();

        this.menuItems = this.filterUserRolMenu(ROUTES, userRole);
        // this.menuItems = ROUTES.filter(menuItem => !menuItem.roles || menuItem.roles.includes(userRole));
        // this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
        // this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    }

    closeMobileMenu(): void {
        if (window.innerWidth < 992) {
            this.isFolded = false;
            this.isExpand = !this.isExpand;
            this.themeService.toggleExpand(this.isExpand);
            this.themeService.toggleFold(this.isFolded);
        }
    }

    filterUserRolMenu(menuItems: SideNavInterface[], userType: string): SideNavInterface[] {
        return menuItems
          .filter(menuItem => !menuItem.roles || menuItem.roles.includes(userType))
          .map(subMenu => {
            if (subMenu.submenu && subMenu.submenu.length > 0) {
              return {
                ...subMenu,
                submenu: subMenu.submenu
                  .filter(subMenuItem => !subMenuItem.roles || subMenuItem.roles.includes(userType))
                  .map(subItem => ({
                    ...subItem,
                    submenu: subItem.submenu ? this. filterUserRolMenu(subItem.submenu, userType) : []
                  }))
              };
            } else {
              return subMenu;
            }
        });
    }

    clearAllTableStates() {
      localStorage.removeItem("table-state-nuevo-ingreso");

      //Matriculacion
      localStorage.removeItem("table-state-aspirantes");

      localStorage.removeItem("table-state-reinscripcion");

      //Carga de calificaciones
      localStorage.removeItem("table-state-recursadores");
      localStorage.removeItem("table-state-ordinarios");

      //Grupos
      localStorage.removeItem("table-state-aulas");
      localStorage.removeItem("table-state-grupos-periodos");
      localStorage.removeItem("table-state-grupos");
      localStorage.removeItem("table-state-modulos");

      localStorage.removeItem("table-state-regularizaciones");

      localStorage.removeItem("table-state-bajas-temporales");

      //Equivalencias
      localStorage.removeItem("table-state-componentes-obligatorios");
      localStorage.removeItem("table-state-componentes-diversificados");

      localStorage.removeItem("table-state-revalidaciones");

      localStorage.removeItem("table-state-convalidaciones");

      localStorage.removeItem("table-state-traslados");

      localStorage.removeItem("table-state-alumnos");

      //Ciclos y Periodos
      localStorage.removeItem("table-state-ciclos");
      localStorage.removeItem("table-state-periodos");

      //Profesores
      localStorage.removeItem("table-state-profesores-cedg");
      localStorage.removeItem("table-state-profesores-cep");

      //Planes de estudio
      localStorage.removeItem("table-state-planes-estudios");
      localStorage.removeItem("table-state-componentes-obligatorios");
      localStorage.removeItem("table-state-componentes diversificados");
      localStorage.removeItem("table-state-componentes-opcionales");

      localStorage.removeItem("table-state-tipos-grupo");

      localStorage.removeItem("table-state-usuarios");

      localStorage.removeItem("table-state-paquetes");

      localStorage.removeItem("table-state-documentos");

      localStorage.removeItem("table-state-tramites");

      localStorage.removeItem("table-state-equivalencia-planes");

      localStorage.removeItem("table-state-planteles");

      //localStorage.removeItem("");

    }
}


export interface IColumn{
    title: string,
    key: string,
    style: string,
    class: string,
    isExport: boolean,
    watch: boolean,
    tag?: boolean,
    type?: IType,
}

export interface IType{
    type: EnumType,
    format: string,
}

export enum EnumMenu{
    NONE, //0
    SIMPLE, //1
    DROPDOWN, //2
    ICONS, //3
}

export interface IInformation{
    title: string,
    typeMenu: EnumMenu,
    columns: IColumn[],
    content: IRow[],
    service: any,
    acciones: IActionMenu[],
    first?: number; // Primer registro a mostrar en la tabla
    rows?: number;
    totalRecords?: number; // Total de registros en la tabla
    loading?: boolean;
    controller?:  string;
}


export interface IInformationSimple{
    title: string,
    typeMenu: EnumMenu,
    columns: IColumn[],
    content: any[],
    service: any,
    acciones: IActionMenu[],
}


export interface IRow{
    content: any,
    tag?: ITagRow | null, //severity TAG - PRIMENG
}

export interface ITagRow
{
    severity: string,
    rounded: boolean,
    icon: string,
    style: string,
}
export interface INZTagRow
{
    class? : string,
    label? : string
}

export enum EnumType{
    FECHA,
    TEXTO,
    NUMERO,
}

export enum TypeResponseMenu{
    DELETE,
}

export interface IResponseMenu{
    type: TypeResponseMenu,
    realizar: boolean,
}

export enum TypeAction {
    EDITAR, //0
    ELIMINAR, //1
    SOLVENTAR, //2
    PERSONALIZADO //3
}

export interface ActionButton{
  action: TypeAction,
  id:     string,
}

export interface IActionMenu{
    type: TypeAction,
    params: IParamAccion[],

}

export interface IParamAccion{
    param: string,
    esRuta: boolean,
    icon?: string,
    tooltip?: string,
    color?: string,
    id?: boolean,
    isDisabled?: boolean,
    emitirId?: boolean,
    emitirIndex?: boolean,
}

export interface ITableLazyLoad {
    actualPage?: number,
    page: number,
    pageSize: number,
    sortField: string,
    sortOrder: number,
    filter: string
    columnasFiltro?: any[];
}

export interface IColumnsExport
{
    title: string,
    key: string,
    isExport: boolean
}

export interface IContentExport{
    columnsExport: string,
    columnsArray: any[],
    filter: string,
    isPDF: boolean
}

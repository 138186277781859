import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { AuthStateModel } from 'src/app/models/states/auth.state.interface';
import { AuthState } from 'src/app/store/auth/states/auth.state';
import { PlantelesService } from 'src/app/services/planteles/planteles.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Roles } from 'src/app/models/authentication/roles.interface';

@Component({
    selector: 'app-quick-view',
    templateUrl: './quick-view.component.html'
})

export class QuickViewComponent {
    @Output() nomPlantel = new EventEmitter<string>();
    plantelesList: any[];
    plantelForm: FormGroup;
    idPlantelSelect: any;
    changePlantel: boolean = false;
    btnHabilitar: boolean = false;
    existPlanteles: boolean;
    plantel: string= '';
    constructor(private store: Store, private fb: FormBuilder, private plantelesService: PlantelesService,
        private authService: AuthenticationService)
    {
        this.plantelForm = this.fb.group({
            idPlantel: [''],
        });
    }

    ngOnInit(): void {
        this.store.select(AuthState.authData).subscribe((authState: AuthStateModel) => {
            this.idPlantelSelect = authState.idPlantel;
            const isAdmin = [Roles.ADMIN, Roles.SA, Roles.CEDG].includes(authState.rol);
            if (isAdmin) {
                this.plantelesService.planteleslist().subscribe( data => {
                    this.plantelesList = data;
                    if(data.length > 0){
                        if(this.idPlantelSelect === null || this.idPlantelSelect === 0){
                            this.idPlantelSelect = data[0].id;
                            this.authService.saveIdPlantel(this.idPlantelSelect);
                        }
                        this.plantelForm.patchValue({
                            idPlantel:  this.idPlantelSelect
                        })
                        if(this.idPlantelSelect !== 0){
                            // this.plantelesService.setPlantelId(this.idPlantelSelect);
                            this.existPlanteles = true;
                            this.plantelName(this.idPlantelSelect);
                        }

                    }else{
                        this.existPlanteles = false;
                        this.authService.saveIdPlantel(null);
                    }
                });
            }
        });

        this.plantelForm.get('idPlantel')?.valueChanges.subscribe(value => {
            this.btnHabilitar = (value !== this.idPlantelSelect);
        });

    }

    plantelName(id: number){
        this.plantelesService.getById(id).subscribe(response =>{
            if(response.success){
                this.plantel = response.data.nombre;
                this.nomPlantel.emit(this.plantel);
            }
        });
    }

    cambiarPlantel(){
        for (const i in this.plantelForm.controls) {
            this.plantelForm.controls[ i ].markAsDirty();
            this.plantelForm.controls[ i ].updateValueAndValidity();
        }
        if (this.plantelForm.valid) {
            var plantel = this.plantelForm.value.idPlantel;
            if(plantel !== this.idPlantelSelect){
                this.plantelName(plantel);
                this.authService.saveIdPlantel(plantel);
                const plantelId = plantel;
                this.plantelesService.setPlantelId(plantelId);
                this.changePlantel = true;
                this.btnHabilitar = false;
                setTimeout(() => {
                    this.changePlantel = false;
                }, 4000);
            }

        }
    }
}


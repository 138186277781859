import { Direccion } from "../direcciones/direccion.interface";

export interface PlantelResponse {
    planteles       :IPlanteles[],
    totalDatos      :number;
}

export interface PlantelesResponse {
  data       :IPlanteles[],
  count      :number;
}

export interface IPlanteles{
  id              :number,
  nombre          :string,
  descripcion     :string,
  cct             :string,
  idDireccion?    :number;
  idSubsistema?   :number;
  creadoPor       :string,
  modificadoPor   :string
}

export interface Plantel {
    id              :number,
    nombre          :string;
    descripcion     :string;
    cct             :string;
    idDireccion?    :number;
    idSubsistema?   :number;
    direccion       :Direccion;
    creadoPor       :string,
    modificadoPor   :string
}

export interface Plantel {
  id              :number,
  nombre          :string;
  descripcion     :string;
  cct             :string;
  idDireccion?    :number;
  idSubsistema?   :number;
  direccion       :Direccion;
  creadoPor       :string,
  modificadoPor   :string
}

export interface buscarAspirantes {
    idPlantel                :number| null,
    idPeriodo                :number,
    estatusDocumentacion     :number | null,
    estatusMatriculacion     :number | null,
    estatusPago              :number | null
}

export interface PlantelPUT {
  id: number;
  cct: string | null;
  clave: string | null;
  idDireccion: number | null;
  direccion: string | null;
  idSubsistema: number | null;
  subsistema: string | null;
  zona: string | null;
  tipo: string | null;
  nombreDirectivo: string | null;
  cargoDirectivo: string | null;
  urlFirmaDirectivo: string | null;
  nombreAcademico: string | null;
  cargoAcademico: string | null;
  urlFirmaAcademico: string | null;
  nombreControlEscolar: string | null;
  cargoControlEscolar: string | null;
  urlFirmaControlEscolar: string | null;
  alumnos: number | null;
  aspirantes: number | null;
  alumnosSemestres: number | null;
  edificios: number | null;
  usuarios: number | null;
  componentesOpcionalesPlanteles: string | null;
  ofertaOpcionalPlanteles: string | null;
  plantelesProfesores: string | null;
  plantelesDirectores: string | null;
  modulos: string | null;
  aulas: string | null;
  tipoGrupos: string | null;
  regularizaciones: string | null;
  nombre: string;
  descripcion: string | null;
  esBorrado: boolean;
}
